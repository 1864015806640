.header {
  margin-top: -1px;
  margin-bottom: 1px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  font-size: 14px;
}

.active-chain {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 20px;
  font-size: 16px;
}

.active-account {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 20px;
}

.account-address {
  font-size: 16px;
}

.disconnect {
  font-size: 14px;
  opacity: 0.7;
  cursor: pointer;
  margin-left: auto;
}

.disconnect:hover {
  color: #0f89e6;
}

@media (max-width: 450px) {
  .header {
    align-items: stretch;
    flex-direction: column;
    padding: 0;
  }

  .active-chain {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .active-account {
    align-items: stretch;
  }

  .account-address {
    margin-bottom: 10px;
  }

  .disconnect {
    margin-left: 0;
  }
}