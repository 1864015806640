.light-box {
  transition: opacity 0.1s ease-in-out;
  text-align: center;
  position: absolute;
  width: 100vw;
  height: 100%;
  margin-left: -50vw;
  top: 0;
  left: 50%;
  z-index: 2;
  will-change: opacity;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.modal-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px;
}

.hit-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card {
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 150px auto 0;
}

.close-button {
  transition: all 0.1s ease-in-out;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 15px;
  top: 15px;
  opacity: 0.5;
  cursor: pointer;
}

.close-button:hover {
  opacity: 1;
}

.close-button:before,
.close-button:after {
  position: absolute;
  content: " ";
  height: 20px;
  width: 2px;
  background: #000000;
}

.close-button:before {
  transform: rotate(45deg);
}

.close-button:after {
  transform: rotate(-45deg);
}

.modal-content {
  width: 100%;
  position: relative;
  word-wrap: break-word;
}