.contentWrapper {
  /* width: 800px; */
}

.wallet {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  min-height: 45vh;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.data-container {
  width: 100%;
  position: relative;
  word-wrap: break-word;
}

.modal-title {
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
}

.data-container {
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
}

.data-table {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.data-row {
  width: 100%;
  display: flex;
  margin: 6px 0;
}

.data-key {
  width: 30%;
  font-weight: 700;
}

.data-value {
  width: 70%;
  font-family: monospace;
}

@media (max-width: 450px) {
  .wallet {
    padding: 0;
    padding-top: 0;
  }
}