.account-assets {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify: center;
}

.asset-row {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
}

.left, .right {
  display: flex;
}